@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary: white;
  --secondary: #630000;
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--secondary) var(--primary);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 1px;
}

*::-webkit-scrollbar-track {
  background: var(--primary);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--secondary);
  border-radius: 14px;
  border: 3px solid var(--primary);
}
.table {
  direction: rtl;
}

.pageitem {
  width: 10px;
  height: 10px;
  margin: 5px;
  background-color: #666;
  border-radius: 50% !important;
}
.page-item {
  width: 25px;
  height: 25px;
  border-radius: 50% !important;
  margin: 10px;
  background: #aaa;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.page-item .page-link {
  width: 110%;
  height: 110%;
  padding: 0;
  border-radius: 50% !important;
  background: #aaa;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}
.page-item .page-link:hover {
  background: var(--secondary);
}
.selected .page-link {
  background: var(--secondary) !important;
  color: white !important;
  box-shadow: none;
}
.loading {
  position: fixed;
  z-index: 999;
  top: 50%;
  left: 50%;
  width: 70px;
  height: 70px;
  border: 7px solid var(--secondary);
  border-top-color: transparent;

  border-radius: 50%;
  animation: loading ease 0.6s infinite;
}
@keyframes loading {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.popup {
  background-color: rgba(000, 000, 000, 0.6);
}
