ul li a.active {
  color: #630000 !important;
  background-color: white;
}

.link.active button {
  color: white !important;
  background-color: #835713;
  position: relative;
  z-index: 2;
}

nav a.active {
  color: white !important;
  background-color: #630000 !important;
}